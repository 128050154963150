export enum RouterIcon {
    ABOUT = 'information-outline',
    ADMINISTRATORS = 'security',
    ALERTS = 'bell',
    ANNOUNCEMENTS = 'bullhorn',
    CASH = 'account-cash',
    CLIENTS = 'account-outline',
    CUSTOM_WIDGETS = 'shape',
    DASHBOARDS = 'view-dashboard',
    DATA_FILES = 'file-chart',
    DEALERS = 'account-box-multiple',
    DEFAULT = 'domain',
    DEVICES = 'access-point',
    DEVICES_CONNECTION_LOG = 'format-list-checkbox',
    DASHBOARD_OVERVIEW = 'incognito',
    GEO_FENCING = 'crosshairs-gps',
    HOME = 'dots-grid',
    MODELS = 'book-variant-multiple',
    MODULES = 'sitemap',
    OEMS = 'domain',
    ORGANIZATIONS = 'shield-account',
    OTA = 'cloud-download',
    PAGE_VIEWS = 'table-eye',
    RELEASES = 'format-list-text',
    REPORTS = 'chart-line',
    ROADMAP = 'map',
    SETTINGS = 'cog-outline',
    SIMULATOR = 'beaker',
    SOFTWARE_VERSIONS = 'table-large',
    UI_DEMO = 'creation',
    USERS = 'account-multiple',
}
